/* HOME */

.Home {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  overflow: hidden;
}

.Home-background {
  background-image: url(../images/home/fondo-inicio.png);
  background-size: cover;
  background-position: center bottom;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 0;
}

.Home-logo-familia-pharmaton {
  background-image: url(../images/common/logo-familia-pharmaton.png);
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
  width: 90%;
  height: 50%;
  max-height: 50%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.Home-familia-pharmaton {
  background-image: url(../images/common/familia-pharmaton.png);
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 94%;
  height: 70%;
  position: absolute;
  left: 3%;
  bottom: 0;
  z-index: 2;
}

.Home-franja {
  background-image: url(../images/common/franja-naranja.png);
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 40%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
}

.Home-registrarse-img {
  width:28vw;
  position: absolute;
  right: 5%;
  bottom: 0;
  z-index: 4;
  cursor: pointer;
}

.Home-init-btn {
  background-color: var(--orange);
  color: var(--white);
  font-size: 8vh;
  border-radius: 40vh;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 101;
  width: 35vh;
  height: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.Home-init-btn:hover {
  transform: scale(1.05);
}

/* MODAL REGISTRO */

.Home-registro {
  position: relative;
  z-index: 102;
}

.Home-registro-img {
  height: 60vh;
}

.Home-farmacia-input {
  background-color: transparent;
  border: none;
  color: var(--white);
  text-align: center;
  position: absolute;
  height: 10%;
  width: 80%;
  top: 32%;
  left: 10%;
  font-size: 2.5vh;
}

.Home-nombre-input {
  background-color: transparent;
  border: none;
  color: var(--white);
  text-align: center;
  position: absolute;
  height: 10%;
  width: 80%;
  left: 10%;
  top: 65%;
  font-size: 2.5vh;
}

.Home-continuar-btn {
  position: absolute;
  height: 13%;
  width: 50%;
  left: 25%;
  bottom: 0;
  opacity: 0;
}

.Home-registro-close-btn {
  width: 7vh;
  height: 7vh;
  position: absolute;
  top: 1vh;
  right: 1vh;
  cursor: pointer;
  z-index: 103;
  opacity: 0;
}

.Home-nombre-error {
  position: absolute;
  width: 80%;
  left: 10%;
  top: 78%;
  text-align: center;
  color: var(--orange-dark);
  font-size: 2vh;
}

.Home-registro-error {
  position: absolute;
  width: 80vw;
  left: 10vw;
  bottom: 10vh;
  text-align: center;
  color: var(--white);
  font-size: 2vh;
  z-index: 101;
}