/* RELOJ */

.Reloj {
  position: absolute;
  top: 3vh;
  right: 3vh;
  z-index: 110;
  display: flex;
  align-items: center;
}

.Reloj-texto {
  background-color: var(--white);
  border-radius: 30px 0 0 30px;
  padding: 10px 40px 10px 20px;
  margin-right: -20px;
  font-size: 2.2vh;
}

.Reloj-base {
  background-image: url(../images/common/reloj.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 12vh;
  height: 12vh;
  position: relative;
}

.Reloj-segundero {
  background-image: linear-gradient(0deg, #0000 47%, black 48%, black 80%, #0000 81%);
  position: absolute;
  width: 0.6vh;
  height: 100%;
  border-radius: 6px;
  left: calc(50% - 0.3vh);
  top: 0;
  transform: rotate(0deg);
}