@font-face {
  font-family: 'changeone_regular';
  src: url('../fonts/ChangeOne/changaone-regular-webfont.eot');
  src: url('../fonts/ChangeOne/changaone-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/ChangeOne/changaone-regular-webfont.woff2') format('woff2'),
       url('../fonts/ChangeOne/changaone-regular-webfont.woff') format('woff'),
       url('../fonts/ChangeOne/changaone-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ghotham_bold';
  src: url('../fonts/Gotham/Gotham-Bold.eot');
  src: url('../fonts/Gotham/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Gotham/Gotham-Bold.woff2') format('woff2'),
       url('../fonts/Gotham/Gotham-Bold.woff') format('woff'),
       url('../fonts/Gotham/Gotham-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ghotham_book';
  src: url('../fonts/Gotham/Gotham-Book.eot');
  src: url('../fonts/Gotham/Gotham-Book.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Gotham/Gotham-Book.woff2') format('woff2'),
       url('../fonts/Gotham/Gotham-Book.woff') format('woff'),
       url('../fonts/Gotham/Gotham-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'kidsrock_regular';
  src: url('../fonts/KidsRock/KidsRockDEMO-Regular.eot');
  src: url('../fonts/KidsRock/KidsRockDEMO-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/KidsRock/KidsRockDEMO-Regular.woff2') format('woff2'),
       url('../fonts/KidsRock/KidsRockDEMO-Regular.woff') format('woff'),
       url('../fonts/KidsRock/KidsRockDEMO-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
