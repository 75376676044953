/* RANKING */

.Ranking-background {
  background-image: url(../images/common/fondo-naranja.png);
  background-size: cover;
  background-position: center bottom;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 0;
}

.Ranking-logo-pharmaton {
  background-image: url(../images/common/logo-pharmaton-blanco.png);
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
  width: 40%;
  height: 20%;
  max-height: 50%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 101;
}

.Ranking-franja {
  background-image: url(../images/common/franja-naranja.png);
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 40%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
}

.Ranking-wrapper {
  position: relative;
  width: 80%;
  z-index: 101;
  left: 10%;
  top: 12vh;
  text-align: left;
  font-size: 2vh;
}

.Ranking-title {
  font-family: 'changeone_regular';
  color: var(--white);
  font-size: 7vh;
  margin: 0 0 2vh;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
}

.Ranking-menu {
  display: flex;
  gap: 2vh;
}

.Ranking-button {
  font-family: 'changeone_regular';
  background-color: var(--orange);
  color: var(--white);
  padding: 1vh 2vh 1vh 1vh;
  font-size: 2vh;
  border-radius: 1vh;
  text-align: center;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 1vh;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
  transition-property: all;
  transition-duration: 0.1s;
}

.Ranking-level-img {
  height: 10vh;
}

.Ranking-button.active {
  background-color: var(--blue);
}

.Ranking-table-wrapper {
  height: 55vh;
  margin-top: 2vh;
  background: white;
  border-radius: 2vh;
  overflow: scroll;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
}

.Ranking-table {
  width: 100%;
  display: none;
}

.Ranking-table.active {
  display: table;
}

.Ranking-table thead {
  background-color: var(--white);
  position: sticky;
  top: 0;
}

.Ranking-table thead tr th {
  font-family: 'changeone_regular';
  font-size: 2.5vh;
  padding: 2vh 2vh;
  color: var(--blue);
  border-bottom: solid 1px #e4e4e4;
}

.Ranking-table tbody tr td {
  padding: 1vh 2vh;
}