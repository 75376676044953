/* WELCOME */

.Welcome {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  overflow: hidden;
}

.Welcome-background {
  background-image: url(../images/common/fondo-naranja.png);
  background-size: cover;
  background-position: center bottom;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 0;
}

.Welcome-logo-pharmaton {
  background-image: url(../images/common/logo-pharmaton-blanco.png);
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
  width: 40%;
  height: 20%;
  max-height: 50%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 101;
}

.Welcome-familia-pharmaton {
  background-image: url(../images/common/familia-pharmaton.png);
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 80%;
  height: 56%;
  position: absolute;
  left: 10%;
  bottom: 9%;
  z-index: 2;
}

.Welcome-empecemos-img {
  position: absolute;
  height: 12vh;
  right: 5%;
  bottom: 0;
  z-index: 4;
  cursor: pointer;
}

.Welcome-franja {
  background-image: url(../images/common/franja-naranja.png);
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 40%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
}

.Welcome-title-img {
  height: 26vh;
  position: absolute;
  left: 0;
  top: 10%;
  z-index: 4;
}

.Welcome-subtitle-img {
  position: absolute;
  left: 5%;
  bottom: 12vh;
  height: 16vh;
  z-index: 4;
}

/* MODAL MENU */

.Welcome-modal {
  z-index: 101;
  width: 95vh;
}

.Welcome-title-levels-img {
  height: 12vh;
  margin: 0 auto 2vh;
  display: block;
}

.Welcome-levels {
  display: flex;
  flex-wrap: wrap;
}

.Welcome-level-img {
  width: 50%;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.Welcome-level-img:hover {
  transform: scale(1.2);
}

.Welcome-userdata {
  position: absolute;
  font-size: 2vh;
  left: 3vh;
  top: 3vh;
  display: flex;
  align-items: center;
  color: var(--white);
  z-index: 101;
}

.Welcome-username {
  margin-left: 15px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.Welcome-logout-btn {
  font-size: 100%;
  line-height: 100%;
  box-shadow: none;
  text-transform: uppercase;
  background-color: var(--purple);
  color: var(--white);
  margin: 0;
  padding: 1vh 2vh;
  border-radius: 8px;
}

.Welcome-ranking-btn {
  font-size: 100%;
  line-height: 100%;
  box-shadow: none;
  text-transform: uppercase;
  background-color: var(--purple);
  color: var(--white);
  margin: 0;
  padding: 1vh 2vh;
  border-radius: 8px;
  position: absolute;
  font-size: 4vh;
  bottom: 3vh;
  z-index: 101;
  left: 2vw;
}