/* NIVEL 2 */

.Level2 {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  overflow: hidden;
}

.Level2-background {
  background-image: url(../images/level-2/fondo-1.png);
  background-size: cover;
  background-position: center bottom;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 0;
}

.Level2-background-2 {
  background-image: url(../images/level-2/fondo-2.png);
  background-size: cover;
  background-position: center bottom;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 0;
}

.Level2-logo-pharmaton {
  background-image: url(../images/common/logo-pharmaton-naranja.png);
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
  width: 40%;
  height: 50%;
  max-height: 50%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.Level2-hombre {
  background-image: url(../images/level-2/hombre.png);
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
  width: 30%;
  height: 50%;
  position: absolute;
  right: 15%;
  bottom: 10%;
  z-index: 2;
}

.Level2-hombre-nina {
  background-image: url(../images/level-2/hombre-nina.png);
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
  width: 30%;
  height: 50%;
  position: absolute;
  right: 15%;
  bottom: 10%;
  z-index: 2;
}

.Level2-presentacion {
  background-image: url(../images/level-2/cuadro-inmunidad.png);
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  width: 55%;
  height: 60%;
  position: absolute;
  left: 0;
  top: 50vh;
  margin-top: -25vh;
  cursor: pointer;
  z-index: 4;
}

.Level2-mensaje {
  background-image: url(../images/level-2/mensaje-empezar.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 55%;
  height: 60%;
  position: absolute;
  left: 5%;
  top: 50vh;
  margin-top: -25vh;
  cursor: pointer;
  z-index: 4;
}

.Level2-mensaje-win {
  background-image: url(../images/level-2/mensaje-logramos.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 55%;
  height: 60%;
  position: absolute;
  left: 5%;
  top: 50vh;
  margin-top: -25vh;
  cursor: pointer;
  z-index: 4;
}

.Level2-franja {
  background-image: url(../images/common/franja-naranja.png);
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 40%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
}

.Level2-nivel {
  background-image: url(../images/level-2/nivel-2.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  right: 5%;
  bottom: 5%;
  width: 30%;
  height: 15%;
  z-index: 4;
}

/* CALLBACKS */

.Level2-fallaste-img {
  width: 100%;
  padding: 20px 40px 0;
}

.Level2-modal-texto {
  font-size: 120%;
  text-align: center;
  color: var(--blue);
  margin: 20px 30px 40px;
}

.Level2-continuar-btn {
  font-size: 150%;
  background-color: var(--blue);
  color: var(--white);
  border-radius: 15px 15px 0 0;
  width: auto;
  margin: 20px auto 0;
  display: block;
  padding: 15px 30px;
}

/* MODAL */

.Level2-modal {
  position: relative;
  z-index: 101;
}

.Level2-alerta-img {
  max-height: 65vh;
  max-width: 60vw;
  z-index: 101;
  cursor: pointer;
}

.Level2-referencias-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 11vh;
  cursor: pointer;
  z-index: 102;
}

.Level2-calificanos-btn {
  position: absolute;
  right: auto;
  left: auto;
  bottom: 1vh;
  height: 7vh;
  cursor: pointer;
  z-index: 102;
}

.Level2-menu-btn {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 11vh;
  cursor: pointer;
  z-index: 102;
}

.Level2-pregunta-close-btn {
  width: 7vh;
  height: 7vh;
  position: absolute;
  top: 3vh;
  right: 4vh;
  cursor: pointer;
  z-index: 103;
  opacity: 0;
}

/* JUEGO */

.Level2-game {
  position: relative;
  overflow: hidden;
}

.Level2-comenzar-btn {
  background-color: var(--orange);
  color: var(--white);
  border-radius: 20px;
  font-size: 200%;
  position: absolute;
  width: 200px;
  height: 80px;
  left: calc(50% - 100px);
  top: calc(50% - 40px);
  z-index: 101;
}

.Level2-ciudad {
  background-image: url(../images/level-2/fondo-interactivo-cielo.png);
  background-position: left center;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.Level2-calle {
  background-image: url(../images/level-2/fondo-interactivo-arboles.png);
  background-position: left center;
  background-size: cover;
  position: absolute;
  height: 80%;
  width: 100%;
  left: 0;
  bottom: 0;
}

.Level2-panning {
  animation-name: panning;
  animation-duration: var(--Level2-race-duration);
  animation-iteration-count:  var(--Level2-race-count);
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes panning {
  0% {
    background-position: left center;
  }
  100% {
    background-position: right center;
  }
}

/* PERSONAJE */

.Level2-hombre-quieto {
  background-image: url(../images/level-2/hombre-corriendo.png);
  background-position: 17% center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  position: absolute;
  height: 29vh;
  width: calc(29vh * 0.7566);
  left: 15%;
  bottom: 3%;
}

.Level2-hombre-corriendo {
  background-image: url(../images/level-2/hombre-corriendo.png);
  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  position: absolute;
  height: 29vh;
  width: calc(29vh * 0.7566);
  left: 15%;
  bottom: 3%;
  animation-name: running;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: steps(6);
}

@keyframes running {
  0% {
    background-position: left center;
  }
  100% {
    background-position: right center;
  }
}

.Level2-hombre-saltando {
  background-image: url(../images/level-2/hombre-saltando.png);
  background-position: left center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  position: absolute;
  height: 32vh;
  width: calc(32vh * 0.677);
  left: 15%;
  bottom: 3%;
  animation-name: jumping;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: steps(11);
}

@keyframes jumping {
  0% {
    background-position: 0% center;
  }
  10% {
    transform: translateY(-40vh);
  }
  60% {
    transform: translateY(-43vh);
  }
  80% {
    transform: translateY(-42vh);
  }
  100% {
    background-position: 100% center;
  }
}

.Level2-hombre-agachado {
  background-image: url(../images/level-2/hombre-agachado.png);
  background-position: left center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  position: absolute;
  height: 26vh;
  width: calc(26vh * 0.8574);
  left: 15%;
  bottom: 3%;
  animation-name: bending;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: steps(11);
}

@keyframes bending {
  0% {
    background-position: 0% center;
  }
  100% {
    background-position: 100% center;
  }
}

/* INTERFACE */

.Level2-interface {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
}

/* HEADER */

.Level2-header {
  background-color: var(--blue);
  padding: 3vh;
  display: flex;
}

/* ENERGIA */

.Level2-energy {
  display: flex;
  width: 30%;
  align-items: center;
  margin-right: 20px;
}

.Level2-energy-icon {
  background-image: url(../images/level-2/energia-icono.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}

.Level2-energy-bar {
  background-color: var(--blue-dark);
  height: 20px;
  width: 100%;
  border-radius: 40px;
  border: solid 3px var(--blue-light);
  padding: 2px;
}

.Level2-energy-fill {
  background-image: linear-gradient(var(--blue-light) 20%, var(--blue) 140%);
  height: 100%;
  width: 100%;
  border-radius: 40px;
}

.Level2-reducing {
  animation-name: reducing;
  animation-duration: var(--Level2-energy-duration);
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes reducing {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

.Level2-energy-bar {
  background-color: var(blue-dark);
  height: 20px;
  width: 100%;
}

/* VIDA */

.Level2-life {
  display: flex;
  width: 30%;
  align-items: center;
  margin-right: 20px;
}

.Level2-life-icon {
  background-image: url(../images/level-2/vidas-icono.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}

.Level2-life-bar {
  background-color: var(--blue-dark);
  height: 20px;
  width: 100%;
  border-radius: 40px;
  border: solid 3px var(--blue-light);
  padding: 2px;
}

.Level2-life-fill {
  background-image: linear-gradient(var(--orange-light) 0%, var(--orange) 100%);
  height: 100%;
  width: 100%;
  border-radius: 40px;
  transition-property: all;
  transition-duration: 1s;
}

.Level2-life-bar {
  background-color: var(blue-dark);
  height: 20px;
  width: 100%;
}

/* FOOTER */

.Level2-footer {
  background-color: var(--blue);
  padding: 3vh;
  display: flex;
}

/* PROGRESO */

.Level2-progress {
  display: flex;
  width: 65%;
  align-items: center;
  position: relative;
}

.Level2-progress-icon-start {
  background-image: url(../images/level-2/juego-icono-inicio.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}

.Level2-progress-icon-end {
  background-image: url(../images/level-2/juego-icono-final.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}

.Level2-progress-icon-avatar {
  background-image: url(../images/level-2/juego-icono-hombre.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 100%;
  top: -9px;
  margin-left: -15px;
}

.Level2-progress-bar {
  background-color: var(--blue-dark);
  height: 20px;
  width: 100%;
  border-radius: 40px;
  border: solid 3px var(--blue-light);
  padding: 2px;
}

.Level2-progress-fill {
  background-image: linear-gradient(var(--orange-light) 0%, var(--orange) 100%);
  height: 10px;
  width: 0;
  border-radius: 40px;
  position: relative;
}

.Level2-filling {
  animation-name: filling;
  animation-duration: var(--Level2-race-duration);
  animation-iteration-count:  var(--Level2-race-count);
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes filling {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

/* CONTROLES */

.Level2-controls {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-items: end;
  width: 35%;
}

.Level2-control-up {
  background-image: url(../images/level-2/juego-flecha-amarilla-arriba.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  margin-left: 30px;
  margin-right: 25px;
}

.Level2-control-down {
  background-image: url(../images/level-2/juego-flecha-amarilla-abajo.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

/* FLOTANTES */

.Level2-bacteria1 {
  background-image: url(../images/level-2/bacteria-1.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 10vh;
  height: 10vh;
  position: absolute;
  left: 0;
  top: 70%;
  z-index: 101;
}

.Level2-bacteria2 {
  background-image: url(../images/level-2/bacteria-2.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 12vh;
  height: 12vh;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 101;
}

.Level2-producto {
  background-image: url(../images/level-2/producto-juego.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20vh;
  height: 20vh;
  position: absolute;
  left: 0;
  top: 20%;
  z-index: 101;
}

.Level2-floating {
  left: 150%;
  animation-duration: var(--Level2-floating-duration);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-play-state: paused;
}

@keyframes floating1 {
  0% {
    left: 200%;
  }
  100% {
    left: -100%;
  }
}

@keyframes floating2 {
  0% {
    left: 180%;
  }
  100% {
    left: -120%;
  }
}

@keyframes floating3 {
  0% {
    left: 160%;
  }
  100% {
    left: -140%;
  }
}

@keyframes floating4 {
  0% {
    left: 140%;
  }
  100% {
    left: -160%;
  }
}

@keyframes floating5 {
  0% {
    left: 120%;
  }
  100% {
    left: -180%;
  }
}

.Level2-floating1 {
  animation-name: floating1;
}

.Level2-floating2 {
  animation-name: floating2;
}

.Level2-floating3 {
  animation-name: floating3;
}

.Level2-floating4 {
  animation-name: floating4;
}

.Level2-floating5 {
  animation-name: floating5;
}

.Level2-paused {
  animation-play-state: paused;
}

.Level2-running {
  animation-play-state: running;
}

.Level2-colision {
  background-position: center top 80%;
  background-size: 30%;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  animation-duration: 0.7s;
  animation-timing-function:cubic-bezier(0.1, 1, 0.5, .8);
  animation-iteration-count: infinite;
}

.Level2-colision-bien {
  background-image: url(../images/level-2/burbuja-bien.png);
  animation-name: buble;
}

.Level2-colision-mal {
  background-image: url(../images/level-2/burbuja-mal.png);
  animation-name: buble;
}

@keyframes buble {
  0% {
    background-position: center top 35%;
    background-size: 10%;
  }
  30% {
    opacity: 1;
  }
  100% {
    background-position: center top 10%;
    opacity: 0;
  }
}

/* JUEGO OK */

.Level2-juego-ok {
  display: flex;
  align-items: center;
  justify-content: center;
}