/* NIVEL 4 */

.Level4 {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  overflow: hidden;
}

.Level4-background {
  background-image: url(../images/level-4/fondo.png);
  background-size: cover;
  background-position: center bottom;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 0;
}

.Level4-logo-pharmaton {
  background-image: url(../images/common/logo-pharmaton-naranja.png);
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
  width: 40%;
  height: 50%;
  max-height: 50%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.Level4-personas {
  background-image: url(../images/level-4/personas.png);
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
  width: 50%;
  height: 80%;
  position: absolute;
  right: 2%;
  bottom: 0;
  z-index: 2;
}

.Level4-presentacion {
  background-image: url(../images/level-4/cuadro-pharmaton.png);
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  width: 50%;
  height: 60%;
  position: absolute;
  left: 0;
  top: 50vh;
  margin-top: -25vh;
  cursor: pointer;
  z-index: 4;
}

.Level4-mensaje {
  background-image: url(../images/level-4/mensaje-empezar.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 48%;
  height: 60%;
  position: absolute;
  left: 5%;
  top: 50vh;
  margin-top: -25vh;
  cursor: pointer;
  z-index: 4;
}

.Level4-franja {
  background-image: url(../images/common/franja-naranja.png);
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 40%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
}

.Level4-nivel {
  background-image: url(../images/level-4/nivel-4.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  right: 5%;
  bottom: 5%;
  width: 30%;
  height: 15%;
  z-index: 4;
}

/* JUEGO */

.Level4-marco {
  background-color: var(--white);
  width: 113vh;
  height: 73vh;
  border-radius: 30px;
  border: solid 2px var(--orange);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.8);
  position: absolute;
  z-index: 102;
}

.Level4-base {
  background-color: var(--white);
  width: 110vh;
  height: 70vh;
  border-radius: 40px;
  position: relative;
  z-index: 102;
  margin-left: 2vh;
}

.Level4-score {
  font-family: 'changeone_regular';
  background-color: var(--orange);
  text-align: center;
  color: var(--white);
  width: 20vw;
  height: 10vh;
  border-radius: 2.5vh 2.5vh 0 0;
  border: solid 2px var(--white);
  padding: 1vh 2vh;
  position: absolute;
  top: 5vh;
  z-index: 102;
}

.Level4-score-title {
  margin: 0;
  width: 100%;
  font-size: 2vh;
}

.Level4-score-number {
  font-size: 150%;
  margin: 0;
  width: 100%;
  font-size: 4vh;
}

.Level4-products {
  background-image: url(../images/level-4/cajas-pharmaton.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 14vw;
  height: 20vh;
  z-index: 102;
  left: 4vw;
  bottom: 10vh;
}

.Level4-shots {
  text-align: center;
  position: absolute;
  width: 12vw;
  z-index: 102;
  right: 4vw;
  bottom: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.Level4-shots-title {
  font-family: 'changeone_regular';
  font-size: 2vh;
  background-color: var(--fucsia);
  color: var(--white);
  border: solid 2px var(--white);
  border-radius: 2vh 2vh 0 0;
  padding: 1vh 2vh 7.5vh;
  margin: 0 0 -7vh;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}

.Level4-shots-number {
  font-family: 'changeone_regular';
  font-size: 6vh;
  background-image: url(../images/level-4/bolita-tiros.png);
  background-size: cover;
  background-position: center;
  color: var(--white);
  border-radius: 500px;
  width: 14vh;
  height: 14vh;
  padding: 10px 20px;
  margin: 0;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.4);
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Level4-weapon {
  background-image: url(../images/level-4/bolita-disparar.png);
  background-position: center;
  background-size: 20vh;
  background-repeat: no-repeat;
  width: 100vh;
  height: 100vh;
  position: absolute;
  top: 20vh;
  left: calc(50% - 50vh);
  border-radius: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 102;
}

.Level4-trigger {
  background: red;
  background-position: center;
  background-size: 20vh;
  background-repeat: no-repeat;
  width: 12vh;
  height: 12vh;
  position: absolute;
  top: 64vh;
  left: calc(50% - 6vh);
  border-radius: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 110;
  opacity: 0;
  cursor: pointer;
}

.Level4-cursor {
  width: 4vh;
  height: 4vh;
  position: absolute;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 150%;
  z-index: 105;
  display: none;
}

.Level4-ball {
  background-image: linear-gradient(0deg, var(--orange-light) 50%, var(--orange) 50%);
  background-position: center;
  background-size: 120%;
  background-repeat: no-repeat;
  width: 4vh;
  height: 4vh;
  position: absolute;
  border-radius: 100px;
  top: calc(70vh - 2vh);
  left: calc(50% - 2vh);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80%;
  font-weight: bold;
  transition-property: all;
  transition-duration: 2s;
  transition-timing-function: cubic-bezier(0,.3,.31,1);
  z-index: 105;
}

.Level4-ball-color-red {
  background-image: url(../images/level-4/roja.png);
}

.Level4-ball-color-green {
  background-image: url(../images/level-4/verde.png);
}

.Level4-ball-color-blue {
  background-image: url(../images/level-4/azul.png);
}

.Level4-ball-color-fucsia {
  background-image: url(../images/level-4/morada.png);
}

.Level4-base {
  display: grid;
  grid-template-columns: repeat(26, 1fr);
  grid-template-rows: repeat(18, 1fr);
  justify-items: center;
  align-items: center;
}

.Level4-base .row1,
.Level4-base .row3,
.Level4-base .row5,
.Level4-base .row7,
.Level4-base .row9,
.Level4-base .row11,
.Level4-base .row13,
.Level4-base .row15,
.Level4-base .row17,
.Level4-base .row19,
.Level4-base .row21,
.Level4-base .row23,
.Level4-base .row25 {
  left: 1vh !important;
}

.Level4-base .row2,
.Level4-base .row4,
.Level4-base .row6,
.Level4-base .row8,
.Level4-base .row10,
.Level4-base .row12,
.Level4-base .row14,
.Level4-base .row16,
.Level4-base .row18,
.Level4-base .row20,
.Level4-base .row22,
.Level4-base .row24,
.Level4-base .row26 {
  left: -1vh !important;
}


/* MODAL */

.Level4-modal {
  position: relative;
  z-index: 101;
}

.Level4-alerta-img {
  max-height: 65vh;
  max-width: 60vw;
  z-index: 101;
  cursor: pointer;
}

.Level4-referencias-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 11vh;
  cursor: pointer;
  z-index: 102;
}

.Level4-menu-btn {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 11vh;
  cursor: pointer;
  z-index: 102;
}

.Level4-pregunta-close-btn {
  width: 7vh;
  height: 7vh;
  position: absolute;
  top: 3vh;
  right: 4vh;
  cursor: pointer;
  z-index: 103;
  opacity: 0;
}

/* POPUP */

.Level4-popup {
  display: none;  
}

.Level4-popup.show {
  display: block;  
}