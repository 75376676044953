/* NOT FOUND */

.NotFound-background {
  background-image: url(../images/common/fondo-naranja.png);
  background-size: cover;
  background-position: center bottom;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 0;
}

.NotFound-logo-pharmaton {
  background-image: url(../images/common/logo-pharmaton-blanco.png);
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
  width: 40%;
  height: 20%;
  max-height: 50%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 101;
}

.NotFound-franja {
  background-image: url(../images/common/franja-naranja.png);
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 40%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
}

.NotFound-texto {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 150%;
  z-index: 101;
}