:root {
  --blue: #002f87;
  --blue-dark: #001949;
  --blue-light: #62c4dc;
  --white: #ffffff;
  --black: #000000;
  --orange: #f57e20;
  --orange-dark: #e24110;
  --orange-light: #FDC260;
  --fucsia: #b93074;
  --purple: #7b30b9;

  --Level2-race-duration: 30s;
  --Level2-energy-duration: 10s;
  --Level2-floating-duration: 8s;
  --Level2-race-count: 1;
}