@import './_vars.css';
@import './fonts.css';
@import './anim.css';
@import './Modal.css';
@import './Overlay.css';
@import './Reloj.css';
@import './Home.css';
@import './Welcome.css';
@import './Level1.css';
@import './Level2.css';
@import './Level3.css';
@import './Level4.css';
@import './Finish.css';
@import './Ranking.css';
@import './NotFound.css';
@import './Preload.css';

* {
  box-sizing: border-box;
}

body {
  font-family: "ghotham_book";
  font-size: 16px;
  margin: 0;
}

input {
  font-family: 'ghotham_book';
}

input:focus-visible {
  outline: none;
}

button {
  font-family: 'changeone_regular';
  border: none;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

.button {
  background-color: var(--white);
  color: var(--orange);
  font-family: 'changeone_regular';
  font-size: 3vh;
  width: auto;
  height: auto;
  position: absolute;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  padding: 5px 15px;
  cursor: pointer;
  z-index: 100;
}

.over {
  z-index: 105;
}

.left {
  left: 3vh;
}

.right {
  right: 3vh;
}

.top {
  top: 3vh;
}

.bottom {
  bottom: 3vh;
}

.state {
  position: absolute;
  left: 0;
  top: 0;
  background-color: red;
  font-size: 70%;
  color: white;
  padding: 10px 20px;
  z-index: 999;
  opacity: 1;
  display: none;
}

.state p {
  margin: 0;
}