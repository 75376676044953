/* PRELOAD */

.Preload {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
}

.Preload.hidden {
  animation-name: fadeOut;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.Preload img {
  width: 100%;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}

.Preload-overlay {
  background-image: radial-gradient(var(--orange) 20%, var(--orange-dark) 150%);
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  display: grid;
  align-content: center;
  justify-items: center;
  z-index: 999;
}

.Preload-icon {
  width: 30vh;
  height: 30vh;
  border-radius: 30vh;
  border-style: solid;
  border-width: 4px;
  border-color: #ffffff #ffffff33;
  animation-name: spin;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.Preload-percent {
  font-family: 'changeone_regular', 'Calibri', 'Arial', sans-serif;
  text-align: center;
  color: var(--white);
  margin-top: -18vh;
  font-size: 6vh;
  font-weight: bold;
}

.Preload-text {
  font-family: 'changeone_regular', 'Calibri', 'Arial', sans-serif;
  text-align: center;
  color: var(--white);
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 4vh;
  margin: 2vh;
  animation-name: pulse;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  0% {
    opacity: 1;
  }
}