.Modal {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.Modal-overlay {
  background-color: var(--blue-dark);
  opacity: 0.95;
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 100;
}