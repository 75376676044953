/* NIVEL 3 */

.Level3 {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  overflow: hidden;
}

.Level3-background {
  background-image: url(../images/level-3/fondo.png);
  background-size: cover;
  background-position: center bottom;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 0;
}

.Level3-logo-pharmaton {
  background-image: url(../images/common/logo-pharmaton-naranja.png);
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
  width: 40%;
  height: 50%;
  max-height: 50%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.Level3-abuelitos {
  background-image: url(../images/level-3/abuelitos.png);
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
  width: 30%;
  height: 60%;
  position: absolute;
  right: 6%;
  bottom: 10%;
  z-index: 2;
}

.Level3-presentacion {
  background-image: url(../images/level-3/cuadro-pharmaton.png);
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  width: 60%;
  height: 60%;
  position: absolute;
  left: 0;
  top: 50vh;
  margin-top: -25vh;
  cursor: pointer;
  z-index: 4;
}

.Level3-mensaje {
  background-image: url(../images/level-3/mensaje-empezar.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 55%;
  height: 60%;
  position: absolute;
  left: 5%;
  top: 50vh;
  margin-top: -25vh;
  cursor: pointer;
  z-index: 4;
}

.Level3-franja {
  background-image: url(../images/common/franja-naranja.png);
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 40%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
}

.Level3-nivel {
  background-image: url(../images/level-3/nivel-3.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  right: 5%;
  bottom: 5%;
  width: 30%;
  height: 15%;
  z-index: 4;
}

/* JUEGO */

.Level3-juego-titulo-img {
  position: absolute;
  height: 16vh;
  border-radius: 0 20px 20px 0;
  left: 0;
  top: 19vh;
  z-index: 101;
}

.Level3-juego-paletas {
  background-color: var(--white);
  position: absolute;
  border-radius: 2vh;
  box-shadow: 0 5px 2vh rgba(0, 0, 0, 0.4);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 2vh;
  padding: 2vh;
  width: 60%;
  height: 45vh;
  top: 38vh;
  left: 3%;
  z-index: 101;
}

.Level3-juego-lock {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  border-radius: 20px;
  width: 60%;
  height: 45vh;
  top: 38vh;
  left: 3%;
  z-index: 102;
  opacity: 0;
  display: none;
}

/* MODAL */

.Level3-modal {
  position: relative;
  z-index: 101;
}

.Level3-alerta-img {
  max-height: 65vh;
  max-width: 60vw;
  z-index: 101;
  cursor: pointer;
}

.Level3-referencias-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 11vh;
  cursor: pointer;
  z-index: 102;
}

.Level3-calificanos-btn {
  position: absolute;
  right: auto;
  left: auto;
  bottom: 1vh;
  height: 7vh;
  cursor: pointer;
  z-index: 102;
}

.Level3-menu-btn {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 11vh;
  cursor: pointer;
  z-index: 102;
}

.Level3-pregunta-close-btn {
  width: 7vh;
  height: 7vh;
  position: absolute;
  top: 3vh;
  right: 4vh;
  cursor: pointer;
  z-index: 103;
  opacity: 0;
}

/* CARDS */

.Level3-card {
  background-color: #223b5c;
  background-image: url(../images/level-3/ficha-pharmaton.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: solid 2px white;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.card-active {
  background-color: #ffffff;
  border: solid 2px var(--orange);
}

.card-ok {
  background-color: #ffffff;
  opacity: 1;
}

div[name="omega"].card-active,
div[name="omega"].card-ok {
  background-image: url(../images/level-3/ficha-omega3.png);
}

div[name="b9"].card-active,
div[name="b9"].card-ok {
  background-image: url(../images/level-3/ficha-b9.png);
}

div[name="c"].card-active,
div[name="c"].card-ok {
  background-image: url(../images/level-3/ficha-c.png);
}

div[name="b6"].card-active,
div[name="b6"].card-ok {
  background-image: url(../images/level-3/ficha-b6.png);
}

div[name="b2"].card-active,
div[name="b2"].card-ok {
  background-image: url(../images/level-3/ficha-b2.png);
}

div[name="zn"].card-active,
div[name="zn"].card-ok {
  background-image: url(../images/level-3/ficha-zn.png);
}

/* CALLBACKS */

.Level3-callback {
  display: none;
}

.Level3-overlay {
  display: none;
}

.Level3-overlay.show {
  display: block;
}