/* FINISH */

.Finish {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  overflow: hidden;
}

.Finish-background {
  background-image: url(../images/common/fondo-naranja.png);
  background-size: cover;
  background-position: center bottom;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 0;
}

.Finish-logo-pharmaton {
  background-image: url(../images/common/logo-pharmaton-blanco.png);
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
  width: 40%;
  height: 20%;
  max-height: 50%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 101;
}

.Finish-familia-pharmaton {
  background-image: url(../images/common/familia-pharmaton.png);
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 80%;
  height: 56%;
  position: absolute;
  left: 10%;
  bottom: 9%;
  z-index: 2;
}

.Finish-franja {
  background-image: url(../images/common/franja-naranja.png);
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 40%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
}

.Finish-title-img {
  position: absolute;
  height: 10vh;
  left: 0;
  top: 8vh;
  z-index: 4;
}

.Finish-subtitle-img {
  position: absolute;
  height: 14vh;
  left: 8vw;
  top: 20vh;
  z-index: 4;
}

.Finish-subtitle-img {
  position: absolute;
  height: 14vh;
  left: 8vw;
  top: 20vh;
  z-index: 4;
}

.Finish-evaluation {
  position: absolute;
  bottom: 5vh;
  z-index: 4;
  text-align: center;
  width: 100%;
}

.Finish-buena-img,
.Finish-regular-img,
.Finish-mala-img {
  height: 12vh;
  margin: 0 1%;
  cursor: pointer;
  text-align: center;
}

.Finish-adios {
  position: relative;
  height: 100vh;
  display: grid;
  align-content: center;
  justify-items: center;
  flex-wrap: wrap;
  z-index: 101;
}

.Finish-apreciamos-img {
  display: block;
  width: 80vh;
  margin-bottom: 4vh;
}

.Finish-adios-img {
  display: block;
  width: 40vh;
  cursor: pointer;
}

.Finish-logo-pharmaton-img {
  position: absolute;
  width: 50vw;
  left: 25vw;
  top: 35vh;
  z-index: 102;
}

.Finish-legal-img {
  position: absolute;
  width: 80vw;
  left: 10vw;
  bottom: 5vh;
  z-index: 102;
}

.Finish-cierre {
  position: absolute;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  z-index: 101;
}
