.zoomIn {
  animation-name: zoomIn;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.zoomOut {
  animation-name: zoomOut;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes zoomOut {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slideUp {
  animation-name: slideUp;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideUp {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}

.slideDown {
  animation-name: slideDown;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100vh);
  }
  100% {
    transform: translateY(0);
  }
}

.slideLeft {
  animation-name: slideLeft;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideLeft {
  0% {
    transform: translateX(-100vh);
  }
  100% {
    transform: translateX(0);
  }
}

.slideRight {
  animation-name: slideRight;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideRight {
  0% {
    transform: translateX(100vh);
  }
  100% {
    transform: translateX(0);
  }
}

.bounceIn {
  animation-name: bounceIn;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes bounceIn {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1.2);
  }
  80% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

