/* NIVEL 1 */

.Level1 {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  overflow: hidden;
}

.Level1-background {
  background-image: url(../images/level-1/fondo-cuarto-nina.png);
  background-size: cover;
  background-position: center bottom;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 0;
}

.Level1-logo-pharmaton {
  background-image: url(../images/common/logo-pharmaton-naranja.png);
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
  width: 40%;
  height: 50%;
  max-height: 50%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.Level1-nina-1 {
  background-image: url(../images/level-1/nina-1.png);
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
  width: 30%;
  height: 50%;
  position: absolute;
  right: 15%;
  bottom: 10%;
  z-index: 2;
}

.Level1-nina-2 {
  background-image: url(../images/level-1/nina-2.png);
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
  width: 30%;
  height: 50%;
  position: absolute;
  right: 15%;
  bottom: 10%;
  z-index: 2;
}

.Level1-presentacion {
  background-image: url(../images/level-1/aviso-morado-creciendo.png);
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  width: 55%;
  height: 60%;
  position: absolute;
  left: 0;
  top: 50vh;
  margin-top: -25vh;
  cursor: pointer;
  z-index: 4;
}

.Level1-mensaje {
  background-image: url(../images/level-1/mensaje-reto-1.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 55%;
  height: 60%;
  position: absolute;
  left: 5%;
  top: 50vh;
  margin-top: -25vh;
  cursor: pointer;
  z-index: 4;
}

.Level1-franja {
  background-image: url(../images/common/franja-naranja.png);
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 40%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
}

.Level1-nivel {
  background-image: url(../images/level-1/nivel-1.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  right: 5%;
  bottom: 5%;
  width: 30%;
  height: 15%;
  z-index: 4;
}

/* SECCION */

.Level1-seccion {
  position: relative;
  z-index: 102;
}

.Level1-seccion-img {
  position: relative;
  height: 100%;
  max-height: 70vh;
  display: block;
  z-index: 102;
}

.Level1-pregunta-btn {
  background-color: red;
  opacity: 0;
  position: absolute;
  height: 9%;
  width: 28%;
  z-index: 102;
}

.Level1-pregunta1-btn {
  left: 0;
  top: 55%;
}

.Level1-pregunta2-btn {
  left: 0;
  top: 40%;
}

.Level1-pregunta3-btn {
  left: 0;
  top: 24%;
}

.Level1-fin-btn {
  left: 36%;
  bottom: 2%;
}

.Level1-pregunta1 {
  position: relative;
  z-index: 101;
}

/* PREGUNTA */

.Level1-pregunta {
  position: relative;
  z-index: 102;
}

/* PREGUNTA 1 */

.Level1-pregunta1-img {
  height: 70vh;
}

.Level1-pregunta1-respuesta1-btn {
  position: absolute;
  left: 10%;
  top: 29vh;
  width: 80%;
  height: 6vh;
  opacity: 0;
}

.Level1-pregunta1-respuesta2-btn {
  position: absolute;
  left: 10%;
  top: 40vh;
  width: 80%;
  height: 6vh;
  opacity: 0;
}

.Level1-pregunta1-respuesta3-btn {
  position: absolute;
  left: 10%;
  top: 51vh;
  width: 80%;
  height: 6vh;
  opacity: 0;
}

/* PREGUNTA 2 */

.Level1-pregunta2-img {
  height: 70vh;
}

.Level1-pregunta2-respuesta1-btn {
  position: absolute;
  left: 10%;
  top: 29vh;
  width: 80%;
  height: 6vh;
  opacity: 0;
}

.Level1-pregunta2-respuesta2-btn {
  position: absolute;
  left: 10%;
  top: 40vh;
  width: 80%;
  height: 6vh;
  opacity: 0;
}

.Level1-pregunta2-respuesta3-btn {
  position: absolute;
  left: 10%;
  top: 51vh;
  width: 80%;
  height: 6vh;
  opacity: 0;
}

/* PREGUNTA 3 */

.Level1-pregunta3-img {
  height: 70vh;
}

.Level1-pregunta3-respuesta1-btn {
  position: absolute;
  left: 10%;
  top: 36vh;
  width: 80%;
  height: 6vh;
  opacity: 0;
}

.Level1-pregunta3-respuesta2-btn {
  position: absolute;
  left: 10%;
  top: 47vh;
  width: 80%;
  height: 6vh;
  opacity: 0;
}

.Level1-pregunta-close-btn {
  width: 7vh;
  height: 7vh;
  position: absolute;
  top: 3vh;
  right: 4vh;
  cursor: pointer;
  z-index: 103;
  opacity: 0;
}

/* TIMEOUT */

.Level1-alerta-img {
  max-height: 50vh;
  z-index: 101;
  cursor: pointer;
}

/* DESIFRAR MENSAJES */

.Level1-palabra-1-btn {
  left: 30%;
  top: 29%;
}

.Level1-palabra-2-btn {
  left: 30%;
  top: 43%;
}

.Level1-palabra-3-btn {
  left: 30%;
  top: 57%;
}

.Level1-palabra-4-btn {
  left: 30%;
  top: 72%;
}

.Level1-palabra {
  background: none;
  position: absolute;
  width: 30%;
  border: none;
  color: var(--blue);
  text-align: center;
  font-size: 2vh;
  text-transform: uppercase;
  z-index: 103;
}

.Level1-palabra-1 {
  height: 9%;
  left: 60%;
  top: 29%;
}

.Level1-palabra-2 {
  height: 9%;
  left: 60%;
  top: 43%;
}

.Level1-palabra-3 {
  height: 9%;
  left: 60%;
  top: 57%;
}

.Level1-palabra-4 {
  height: 9%;
  left: 60%;
  top: 72%;
}

/* MODAL */

.Level1-modal {
  position: relative;
  z-index: 101;
}

.Level1-alerta-img {
  max-height: 65vh;
  max-width: 60vw;
  z-index: 101;
  cursor: pointer;
}

.Level1-referencias-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 11vh;
  cursor: pointer;
  z-index: 102;
}

.Level1-calificanos-btn {
  position: absolute;
  right: auto;
  left: auto;
  bottom: 1vh;
  height: 7vh;
  cursor: pointer;
  z-index: 102;
}

.Level1-menu-btn {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 11vh;
  cursor: pointer;
  z-index: 102;
}

.Level1-pregunta-close-btn {
  width: 7vh;
  height: 7vh;
  position: absolute;
  top: 3vh;
  right: 4vh;
  cursor: pointer;
  z-index: 103;
  opacity: 0;
}